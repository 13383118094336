import * as actions from '../constants/actionTypes';

export const getPartners = () => async (dispatch) => {
  try {
    dispatch(partnersFetching());

    const response = await fetch('https://api.orbitrush.com/partners');
    const result = await response.json();

    dispatch(setPartners(result));

    return result;
  } catch (error) {
    dispatch(partnersFailure(error));
  }
};

export const addGeo = (data) => async (dispatch) => {
  try {
    dispatch(partnersFetching());

    const response = await fetch('https://api.orbitrush.com/partners', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    dispatch(getPartners());

    return response;
  } catch (error) {
    dispatch(partnersFailure(error));
  }
};

export const addCpa = (data) => async (dispatch) => {
  try {
    dispatch(partnersFetching());

    const response = await fetch('https://api.orbitrush.com/partners', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    dispatch(getPartners());

    return response;
  } catch (error) {
    dispatch(partnersFailure(error));
  }
};

export const removeCpa = (data) => async (dispatch) => {
  try {
    dispatch(partnersFetching());

    const response = await fetch('https://api.orbitrush.com/partners', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        _id: data,
      }),
    });

    dispatch(getPartners());

    return response;
  } catch (error) {
    dispatch(partnersFailure(error));
  }
};

export const updateCpa = (data) => async (dispatch) => {
  try {
    dispatch(partnersFetching());

    const response = await fetch('https://api.orbitrush.com/partners', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    dispatch(getPartners());

    return response;
  } catch (error) {
    dispatch(partnersFailure(error));
  }
};

export const changePartner = (currentPartner) => ({
  type: actions.CHANGE_PARTNER,
  currentPartner,
});

const partnersFetching = (status) => ({
  type: actions.PARTNERS_FETCHING,
  status,
});

const partnersFailure = (error) => ({
  type: actions.PARTNERS_FAILURE,
  error,
});

export const setPartners = (data) => ({
  type: actions.SET_PARTNERS,
  data,
});
