import moment from 'moment';

export const ranges = {
  today: [new Date(), new Date()],
  yesterday: [
    moment().add(-1, 'days').toDate(),
    moment().add(-1, 'days').toDate(),
  ],
  week: [
    moment().startOf('isoweek').toDate(),
    moment().endOf('isoweek').toDate(),
  ],
  previousWeek: [
    moment().subtract(1, 'weeks').startOf('isoWeek').toDate(),
    moment().subtract(1, 'weeks').endOf('isoWeek').toDate(),
  ],
  month: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
  previousMonth: [
    moment().subtract(1, 'months').startOf('month').toDate(),
    moment().subtract(1, 'months').endOf('month').toDate(),
  ],
};

export const settings = {
  host: 'https://orbitrush.com',
  hostJSON: 'https://all-credits.com.ua/mfo',
  defaultGeo: 'ua',
};

export const listSources = [
  { value: 'googlePlay', label: 'Google Play' },
  { value: 'appStore', label: 'App Store' },
];

export const listAppStatuses = [
  { value: 'active', label: 'Active' },
  { value: 'blocked', label: 'Blocked' },
];

export const selectInitialValue = [{ value: 'all', label: 'All' }];

export const getSource = (str) => {
  switch (str) {
    case 'Google Ads ACI':
      return 'google';
    case 'Facebook Installs':
      return 'facebook';
    case 'Instagram Installs':
      return 'instagram';
    case 'Organic':
      return 'organic';
    default:
      return 'unattributed';
  }
};

export const replaceLink = (link, params) => {
  let result = link;

  for (let key in params) {
    if (result.includes(key)) {
      const start = result.indexOf(key);

      result =
        result.slice(0, start) + params[key] + result.slice(start + key.length);
    }
  }

  return result;
};

export const parseOffers = ({ list, categories }) =>
  list.map((item) => ({
    ...item,
    categories: categories.reduce((acc, { value }) => {
      acc[value] = item.categories.includes(value);

      return acc;
    }, {}),
  }));

export const convertOffers = (list, cpaNetworks, categories) =>
  list.map((item) => ({
    ...item,
    url: replaceLink(cpaNetworks[item.cpa], {
      '{offer_id}': item.offer_id,
    }),
    categories: Object.values(categories).reduce((acc, { value }) => {
      if (item.categories[value]) {
        acc.push(value);
      }

      return acc;
    }, []),
  }));

export const offerTemplate = {
  isHidden: true,
  offer_name: '',
  offer_id: '',
  cpa: 'pdl',
  url: '',
  description: '',
  time_solution: null,
  percent: {
    from: null,
    to: null,
  },
  amount: {
    from: null,
    to: null,
  },
  term: {
    from: null,
    to: null,
  },
  detail: {
    site: '',
    phone: '',
    email: '',
    address: '',
    license: '',
    apr: '',
  },
  top: false,
  push: {
    isActive: false,
    img: '',
    description: '',
  },
  categories: [],
};

export const formatDate = () => {
  let d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }

  if (day.length < 2) {
    day = '0' + day;
  }

  return [day, month, year].join('-');
};

export const operators = {
  ua: [
    'Yezzz!',
    'lifecell,DJUICE',
    'KYIVSTAR',
    'Kyivstar',
    'UA-KYIVSTAR',
    'UA-KYIVSTAR | KYIVSTAR',
    'UA-KYIVSTAR,MTS UKR',
    'DJUICE,UA-KYIVSTAR',
    'lifecell,KYIVSTAR',
    'Vodafone UA,KYIVSTAR',
    'lifecell',
    'BudVdoma',
    "BUD' VDOMA",
    "Bud' vdoma",
    "Bud' vdoma",
    'Vodafone UA',
    'Vodafone UA | MTS UKR',
    'Vodafone U',
    'life:)',
    'Beeline UA',
    'DJUICE',
    'JEANS',
    'MTS UKR',
    'KYIVSTAR,lifecell',
    'KYIVSTAR,Vodafone UA',
    'UA-KS',
    'Vodafone UA,lifecell',
    'KYIVSTAR,KYIVSTAR',
    'lifecell,lifecell',
    'Киевстар',
    'BudVdoma,KYIVSTAR',
    'lifecell,Vodafone UA',
    'Vodafone UA,Vodafone UA',
    'Golden Telecom',
    'Vodafone UA,Vodafone UA',
    'Vodafone UA,Vodafone UA',
  ],
  ru: [
    'MTS',
    'MTS RUS',
    'Tele2',
    'Beeline',
    'MegaFon',
    'MegaFon #1',
    'YOTA',
    'Tele2 RU',
    'MOTIV',
    'TELE2',
    'Sberbank',
    'SberMobile',
    'MTS-RUS',
    'Летай',
    'DANYCOM',
    'MGTS',
    'MegaFon Fastest',
    'ROSTELECOM',
    'Rostelecom',
    'TELE2 RU',
    'Tinkoff',
    'Megafon',
    'Tele2,MTS RUS',
    'Tele2,MegaFon',
    'VTB',
    'Kyivstar',
    'kievstar',
  ],
};
