import * as actions from '../constants/actionTypes';

import { parseOffers, convertOffers, operators, settings } from '../helpers';

export const getOffers = (appId) => async (dispatch) => {
  try {
    dispatch(offersFetching());

    const response = await fetch(
      `https://api.orbitrush.com/offers?appId=${appId}`,
    );
    const result = await response.json();

    if (result) {
      const data = {
        categories: result.categories,
        list: parseOffers(result),
      };

      dispatch(setOffers(data));

      return data;
    }

    dispatch(setOffers({}));

    return {};
  } catch (error) {
    dispatch(offersFailure(error));
  }
};

export const saveOffers = () => async (dispatch, getState) => {
  const state = getState();
  const { partners } = state;
  const { list, categories } = state.form['offers-offer'].values;
  const { app, geo } = state.form['offers-search'].values;

  const cpaNetworks = Object.entries(partners.data).reduce(
    (acc, [geo, values]) => {
      values.forEach(({ cpa, url }) => {
        acc[cpa] = url;
      });

      return acc;
    },
    {},
  );

  try {
    dispatch(offersFetching());

    await fetch('https://api.orbitrush.com/offers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        appId: app,
        offerData: {
          list: convertOffers(list, cpaNetworks, categories),
          categories,
        },
      }),
    });

    dispatch(getOffers(app));
  } catch (error) {
    dispatch(offersFailure(error));
  }
};

export const saveOffersAs = (apps, type) => async (dispatch, getState) => {
  const state = getState();
  const { partners } = state;
  const { list, categories } = state.form['offers-offer'].values;
  const { geo } = state.form['offers-search'].values;

  const cpaNetworks = Object.entries(partners.data).reduce(
    (acc, [geo, values]) => {
      values.forEach(({ cpa, url }) => {
        acc[cpa] = url;
      });

      return acc;
    },
    {},
  );

  try {
    dispatch(offersFetching());

    const idsArray = Object.entries(apps).reduce((acc, [appId, isActive]) => {
      if (isActive) {
        acc.push(appId.replace(/_/g, '.'));
      }

      return acc;
    }, []);

    if (type === 'data') {
      await fetch('https://api.orbitrush.com/offers', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
          idsArray,
          offerData: {
            list: convertOffers(list, cpaNetworks, categories),
            categories,
          },
        }),
      });
    } else {
      await fetch('https://api.orbitrush.com/offers?saveOrder=true', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
          idsArray,
          listArray: list.map(({ _id }) => _id),
        }),
      });
    }

    dispatch(offersFetching(false));
  } catch (error) {
    dispatch(offersFailure(error));
  }
};

const offersFetching = (status) => ({
  type: actions.OFFERS_FETCHING,
  status,
});

const offersFailure = (error) => ({
  type: actions.OFFERS_FAILURE,
  error,
});

export const setOffers = (data) => ({
  type: actions.SET_OFFERS,
  data,
});
