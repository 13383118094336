// Auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FETCHING = 'LOGIN_FETCHING';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// Apps
export const SET_APPS = 'SET_APPS';
export const CHANGE_APP = 'CHANGE_APP';
export const APPS_FETCHING = 'APPS_FETCHING';
export const APPS_FAILURE = 'APPS_FAILURE';

// Partners
export const SET_PARTNERS = 'SET_PARTNERS';
export const CHANGE_PARTNER = 'CHANGE_PARTNER';
export const PARTNERS_FETCHING = 'PARTNERS_FETCHING';
export const PARTNERS_FAILURE = 'PARTNERS_FAILURE';

// Offers
export const SET_OFFERS = 'SET_OFFERS';
export const OFFERS_FETCHING = 'OFFERS_FETCHING';
export const OFFERS_FAILURE = 'OFFERS_FAILURE';
