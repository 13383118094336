import * as firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyB27mY5qdAeKjB43yaYyNApsMvqoskY6tU',
  authDomain: 'pdl-profit.firebaseapp.com',
  databaseURL: 'https://pdl-profit.firebaseio.com',
  projectId: 'pdl-profit',
  storageBucket: 'pdl-profit.appspot.com',
  messagingSenderId: '830575924959',
  appId: '1:830575924959:web:f2a2707b835742c3e37b6a',
};

firebase.initializeApp(config);

export default firebase;
