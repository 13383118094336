import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../components/Loader';
import Modal from '../../components/Modal';

import FormGeo from './formGeo';
import FormApp from './formApp';

import {
  getApps,
  addGeo,
  addApp,
  removeApp,
  updateApp,
} from '../../actions/apps';
import { showModal, closeModal } from '../../actions/modal';

import './index.css';

const initialValuesApp = {
  geo: 'ua',
  source: 'googlePlay',
};

class Apps extends React.Component {
  state = {
    activeApp: null,
  };

  componentDidMount() {
    this.props.onGetApps();
  }

  render() {
    const {
      apps: { fetching, data },
      onAddGeo,
      onAddApp,
      onRemoveApp,
      onUpdateApp,
      onShowModal,
      onCloseModal,
    } = this.props;
    const { activeApp } = this.state;

    return fetching ? (
      <Loader />
    ) : (
      <section className="apps">
        <header className="apps__header">
          <h2 className="title">Apps</h2>

          <div className="apps__actions">
            <button
              className="btn btn_info"
              onClick={() => onShowModal('apps-geo')}
            >
              Add GEO
            </button>

            <button
              className="btn btn_info"
              onClick={() => onShowModal('apps-app')}
            >
              Add APP
            </button>
          </div>
        </header>

        <table>
          <thead>
            <tr>
              <th width="25%">GEO</th>
              <th width="25%">APP</th>
              <th width="25%">Source</th>
              <th width="25%">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([country, values]) =>
              values.map((app, appIndex) => (
                <tr key={appIndex}>
                  {appIndex === 0 && <td rowSpan={values.length}>{country}</td>}
                  <td>{app.name}</td>
                  <td>{app.source}</td>
                  <td>
                    <div className="apps__icons">
                      <span
                        className="icon icon_edit"
                        onClick={() => {
                          this.setState(
                            {
                              activeApp: {
                                ...app,
                                geo: country,
                              },
                            },
                            () => onShowModal('apps-app'),
                          );
                        }}
                      />
                      <span
                        className="icon icon_remove"
                        onClick={() => onRemoveApp(app.appId)}
                      />
                    </div>
                  </td>
                </tr>
              )),
            )}
          </tbody>
        </table>

        <Modal title="GEO" name="apps-geo">
          <FormGeo
            onSubmit={(data) => {
              onAddGeo(data);
              onCloseModal();
            }}
          />
        </Modal>

        <Modal
          title="APP"
          name="apps-app"
          onClose={() => {
            onCloseModal();

            this.setState({
              activeApp: null,
            });
          }}
        >
          <FormApp
            onSubmit={(data) => {
              if (activeApp) {
                this.setState(
                  {
                    activeApp: null,
                  },
                  () => onUpdateApp(data),
                );
              } else {
                onAddApp(data);
              }

              onCloseModal();
            }}
            listGeo={Object.entries(data).map(([country]) => ({
              value: country,
              label: country.toUpperCase(),
            }))}
            initialValues={activeApp || initialValuesApp}
            actionName={activeApp ? 'Update' : 'Add'}
          />
        </Modal>
      </section>
    );
  }
}

export default connect(
  (state) => ({
    apps: state.apps,
  }),
  (dispatch, props) => ({
    onGetApps: () => dispatch(getApps()),
    onAddGeo: (data) => dispatch(addGeo(data)),
    onAddApp: (data) => dispatch(addApp(data)),
    onRemoveApp: (data) => dispatch(removeApp(data)),
    onUpdateApp: (data) => dispatch(updateApp(data)),
    onShowModal: (name) => dispatch(showModal(name)),
    onCloseModal: () => dispatch(closeModal()),
  }),
)(Apps);
