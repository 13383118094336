import * as actions from '../constants/actionTypes';

export const showModal = (data) => ({
  type: actions.SHOW_MODAL,
  data,
});

export const closeModal = () => ({
  type: actions.CLOSE_MODAL,
});
