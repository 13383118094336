import React from 'react';
import { HashRouter as Router, Switch, Redirect } from 'react-router-dom';

import Offers from './pages/Offers';
import Apps from './pages/Apps';
import Partners from './pages/Partners';
import Auth from './pages/Auth';

import Layout from './pages/Layout';
import PrivateRoute from './pages/Layout/privateRoute';
import GuestRoute from './pages/Layout/guestRoute';

import './assets/styles/app.css';

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <PrivateRoute exact path="/offers" component={Offers} />
          <PrivateRoute exact path="/apps" component={Apps} />
          <PrivateRoute exact path="/partners" component={Partners} />
          <GuestRoute exact path="/login" component={Auth} />
          <Redirect from="/" to="apps" />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
