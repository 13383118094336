import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, getFormValues } from 'redux-form';

import Input from '../../components/Input';
import Select from '../../components/Select';

class FormCpa extends React.Component {
  render() {
    const {
      handleSubmit,
      submitting,
      listGeo,
      actionName,
      formCpa: { direct, utmMetrics, has_config } = {},
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="form">
        <Field name="geo" component={Select} list={listGeo} label="GEO" />

        <Field name="cpa" component={Input} label="CPA" />

        <Field name="affiliate_id" component={Input} label="Affiliate ID" />

        <Field name="url" component={Input} label="URL" />

        <Field
          name="direct"
          component={Input}
          className="Input"
          label="Direct"
          type="checkbox"
        />

        {direct && <Field name="offer_id" component={Input} label="Offer ID" />}

        <Field
          name="utmMetrics"
          component={Input}
          className="Input"
          label="UTM metric"
          type="checkbox"
        />

        {utmMetrics && (
          <React.Fragment>
            <Field
              name="utm.client_id"
              component={Input}
              className="Input"
              label="client_id"
            />
            <Field
              name="utm.advertising_id"
              component={Input}
              label="advertising_id"
            />
            <Field name="utm.app" component={Input} label="app" />
            <Field name="utm.source" component={Input} label="source" />
            <Field name="utm.campaign" component={Input} label="campaign" />
            <Field name="utm.adgroup" component={Input} label="adgroup" />
            <Field name="utm.adset" component={Input} label="adset" />
            <Field name="utm.chanel" component={Input} label="chanel" />
            <Field name="utm.geo" component={Input} label="geo" />
          </React.Fragment>
        )}

        <Field
          name="has_config"
          component={Input}
          className="Input"
          label="Config"
          type="checkbox"
        />

        {has_config && (
          <React.Fragment>
            <Field
              name="lead_status_approve_value"
              component={Input}
              label="lead_status"
            />

            <Field
              name="lead_date_format"
              component={Input}
              label="lead_date"
            />
          </React.Fragment>
        )}

        <div className="form__controls">
          <button className="btn" type="submit" disabled={submitting}>
            {actionName}
          </button>
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'partners-cpa',
    enableReinitialize: true,
  }),
  connect((state) => ({
    formCpa: getFormValues('partners-cpa')(state),
  })),
)(FormCpa);
