import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { login } from '../../actions/auth';

import './index.css';

class Auth extends React.Component {
  state = {
    login: '',
    password: '',
  };

  handleChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.onLogin(this.state);
  }

  render() {
    const { login, password } = this.state;

    return (
      <form className="auth">
        <div className="field">
          <label htmlFor="login">Логин</label>
          <input
            name="login"
            className="input"
            type="email"
            onChange={this.handleChange.bind(this)}
            value={login}
          />
        </div>

        <div className="field">
          <label htmlFor="password">Пароль</label>
          <input
            name="password"
            className="input"
            type="password"
            onChange={this.handleChange.bind(this)}
            value={password}
          />
        </div>

        <button className="btn" onClick={this.onSubmit.bind(this)}>
          Войти
        </button>
      </form>
    );
  }
}

export default compose(
  withRouter,
  connect(null, (dispatch) => ({
    onLogin: (user) => dispatch(login(user)),
  })),
)(Auth);
