import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../components/Loader';
import Modal from '../../components/Modal';

import FormGeo from './formGeo';
import FormCpa from './formCpa';

import {
  getPartners,
  addGeo,
  addCpa,
  removeCpa,
  updateCpa,
} from '../../actions/partners';
import { showModal, closeModal } from '../../actions/modal';

import './index.css';

const initialValuesCpa = {
  geo: 'all',
  direct: false,
  utmMetrics: false,
};

class Partners extends React.Component {
  state = {
    activeCpa: null,
  };

  componentDidMount() {
    this.props.onGetPartners();
  }

  render() {
    const {
      partners: { fetching, data },
      onAddGeo,
      onAddCpa,
      onRemoveCpa,
      onUpdateCpa,
      onShowModal,
      onCloseModal,
    } = this.props;
    const { activeCpa } = this.state;

    return fetching ? (
      <Loader />
    ) : (
      <section className="partners">
        <header className="partners__header">
          <h2 className="title">Partners</h2>

          <div className="partners__actions">
            <button
              className="btn btn_info"
              onClick={() => onShowModal('partners-geo')}
            >
              Add GEO
            </button>

            <button
              className="btn btn_info"
              onClick={() => onShowModal('partners-cpa')}
            >
              Add CPA
            </button>
          </div>
        </header>

        <table>
          <thead>
            <tr>
              <th>GEO</th>
              <th>CPA</th>
              <th>Affiliate ID</th>
              <th>Direct</th>
              <th>Offer ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([country, values]) =>
              values.map((cpa, cpaIndex) => (
                <tr key={cpaIndex}>
                  {cpaIndex === 0 && <td rowSpan={values.length}>{country}</td>}
                  <td>{cpa.cpa}</td>
                  <td>{cpa.affiliate_id}</td>
                  <td>
                    {cpa.direct ? (
                      <span className="icon icon_good" />
                    ) : (
                      <span className="icon icon_bad" />
                    )}
                  </td>
                  <td>{cpa.offer_id}</td>
                  <td>
                    <div className="partners__icons">
                      <span
                        className="icon icon_edit"
                        onClick={() => {
                          this.setState(
                            {
                              activeCpa: {
                                ...cpa,
                                geo: country,
                              },
                            },
                            () => onShowModal('partners-cpa'),
                          );
                        }}
                      />
                      <span
                        className="icon icon_remove"
                        onClick={() => onRemoveCpa(cpa._id)}
                      />
                    </div>
                  </td>
                </tr>
              )),
            )}
          </tbody>
        </table>

        <Modal title="GEO" name="partners-geo">
          <FormGeo
            onSubmit={(data) => {
              onAddGeo(data);
              onCloseModal();
            }}
          />
        </Modal>

        <Modal
          title="CPA"
          name="partners-cpa"
          onClose={() => {
            onCloseModal();

            this.setState({
              activeCpa: null,
            });
          }}
        >
          <FormCpa
            onSubmit={(data) => {
              if (activeCpa) {
                this.setState(
                  {
                    activeCpa: null,
                  },
                  () => onUpdateCpa(data),
                );
              } else {
                onAddCpa(data);
              }

              onCloseModal();
            }}
            listGeo={Object.entries(data).map(([country]) => ({
              value: country,
              label: country.toUpperCase(),
            }))}
            initialValues={activeCpa || initialValuesCpa}
            actionName={activeCpa ? 'Update' : 'Add'}
          />
        </Modal>
      </section>
    );
  }
}

export default connect(
  (state) => ({
    partners: state.partners,
  }),
  (dispatch, props) => ({
    onGetPartners: () => dispatch(getPartners()),
    onAddGeo: (data) => dispatch(addGeo(data)),
    onAddCpa: (data) => dispatch(addCpa(data)),
    onRemoveCpa: (data) => dispatch(removeCpa(data)),
    onUpdateCpa: (data) => dispatch(updateCpa(data)),
    onShowModal: (name) => dispatch(showModal(name)),
    onCloseModal: () => dispatch(closeModal()),
  }),
)(Partners);
