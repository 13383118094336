import firebase from '../firebase';
import * as actions from '../constants/actionTypes';

export const login = ({ login, password }) => async (dispatch) => {
  try {
    dispatch(authFetching());

    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(login, password);

    const { uid } = response.user;

    localStorage.setItem('uid', uid);
    dispatch(loginSuccess(uid));
  } catch (error) {
    dispatch(authFailure(error));
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch(authFetching());

    const response = await firebase.auth().signOut();

    localStorage.removeItem('uid');
    dispatch(logoutSuccess());

    return response;
  } catch (error) {
    dispatch(authFailure(error));
  }
};

const authFetching = () => ({
  type: actions.LOGIN_FETCHING,
});

const authFailure = (error) => ({
  type: actions.LOGIN_FAILURE,
  error,
});

const loginSuccess = (uid) => ({
  type: actions.LOGIN,
  uid,
});

const logoutSuccess = () => ({
  type: actions.LOGOUT,
});
