import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';

import Input from '../../components/Input';
import Select from '../../components/Select';

import { listSources, listAppStatuses } from '../../helpers';

class FormApp extends React.Component {
  render() {
    const {
      handleSubmit,
      submitting,
      listGeo,
      actionName,
      formApp: { hasTracker } = {},
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="form">
        <Field name="geo" component={Select} list={listGeo} label="GEO" />

        <Field
          name="source"
          component={Select}
          list={listSources}
          label="Source"
        />

        <Field name="name" component={Input} className="Input" label="Name" />

        <Field
          name="appId"
          component={Input}
          className="Input"
          label="App ID"
        />

        <Field
          name="status"
          component={Select}
          list={listAppStatuses}
          label="Status"
        />

        <Field
          name="hasTracker"
          component={Input}
          className="Input"
          label="Has tracker"
          type="checkbox"
        />

        {hasTracker && (
          <div className="form__field-trackers">
            <h2 className="subtitle">Adjust</h2>

            <Field
              name="app_token"
              component={Input}
              className="Input"
              label="App token"
            />

            <Field
              name="event_token"
              component={Input}
              className="Input"
              label="Event token"
            />

            <Field
              name="s2s_token"
              component={Input}
              className="Input"
              label="S2S token"
            />
          </div>
        )}

        <h2 className="subtitle">Cloak</h2>

        <Field
          name="moderation_mode"
          component={Input}
          className="Input"
          label="Moderation mode"
          type="checkbox"
        />

        <Field
          name="moderation_redirect"
          component={Input}
          className="Input"
          label="Moderation redirect"
        />

        <h2 className="subtitle">Offers filtration</h2>

        <Field
          name="filteredByApproved"
          component={Input}
          className="Input"
          label="Has filtration"
          type="checkbox"
        />

        <div className="form__controls">
          <button className="btn" type="submit" disabled={submitting}>
            {actionName}
          </button>
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'apps-app',
    enableReinitialize: true,
  }),
  connect((state) => ({
    formApp: getFormValues('apps-app')(state),
  })),
)(FormApp);
