import * as actions from '../constants/actionTypes';

const initialState = {
  fetching: true,
  data: {},
  error: null,
};

const offers = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_OFFERS:
      return {
        ...state,
        data: action.data,
        fetching: false,
      };
    case actions.OFFERS_FETCHING:
      return {
        ...state,
        fetching: typeof action.status === 'boolean' ? action.status : true,
      };
    case actions.OFFERS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    default:
      return state;
  }
};

export default offers;
