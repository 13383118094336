import React from 'react';

import { selectInitialValue } from './../../helpers';

const Select = ({
  list,
  input,
  label,
  meta: { submitFailed, error },
  initialValue,
}) => {
  const classInvalid = submitFailed && error ? 'field_invalid' : '';
  const selectList = initialValue ? selectInitialValue.concat(list) : list;

  return (
    <div className={`field ${classInvalid}`}>
      <label>{label}</label>
      <div className="select">
        <select {...input} className="input">
          {selectList.map(({ label, value }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      {submitFailed && error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Select;
