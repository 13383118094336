import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import FormSearch from './formSearch';
import FormOffers from './formOffers';
import FormApps from './formApps';

import { getOffers, saveOffers, saveOffersAs } from '../../actions/offers';
import { showModal, closeModal } from '../../actions/modal';

import { settings } from '../../helpers';

import './index.css';

class Offers extends React.Component {
  state = {
    dropDown: false,
    offersType: null,
  };

  openModal(type) {
    const { onShowModal } = this.props;

    this.setState(
      {
        dropDown: false,
        offersType: type,
      },
      () => onShowModal('offers-apps'),
    );
  }

  render() {
    const {
      offers: { fetching, data },
      onGetOffers,
      onSaveOffers,
      onSaveOffersAs,
      onCloseModal,
    } = this.props;
    const { dropDown, offersType } = this.state;

    return (
      <section className="offers">
        <section className="offers__search">
          <FormSearch
            selectApp={(appId) => onGetOffers(appId)}
            initialValues={{
              geo: settings.defaultGeo,
            }}
          />

          <div className="offers__controls">
            <div className="btn-group">
              <button className="btn" type="button" onClick={onSaveOffers}>
                Save
              </button>
              <button
                className="btn"
                type="button"
                onClick={() => {
                  this.setState(({ dropDown }) => ({
                    dropDown: !dropDown,
                  }));
                }}
              >
                <span className="icon icon_down-white" />
              </button>
              <ul
                className={`btn-group__list ${
                  dropDown ? 'btn-group__list_active' : ''
                }`}
              >
                <li
                  className="btn-group__list-item"
                  onClick={() => this.openModal('order')}
                >
                  Only order
                </li>
                <li
                  className="btn-group__list-item"
                  onClick={() => this.openModal('data')}
                >
                  All data
                </li>
              </ul>
            </div>
          </div>
        </section>

        {fetching ? <Loader /> : <FormOffers initialValues={{ ...data }} />}

        <Modal title={`Save ${offersType}`} name="offers-apps">
          <FormApps
            onSubmit={(data) => {
              onSaveOffersAs(data, offersType);
              onCloseModal();
            }}
          />
        </Modal>
      </section>
    );
  }
}

export default connect(
  (state) => ({
    offers: state.offers,
  }),
  (dispatch, props) => ({
    onGetOffers: (appId) => dispatch(getOffers(appId)),
    onSaveOffers: (appId) => dispatch(saveOffers(appId)),
    onSaveOffersAs: (apps, type) => dispatch(saveOffersAs(apps, type)),
    onShowModal: (name) => dispatch(showModal(name)),
    onCloseModal: () => dispatch(closeModal()),
  }),
)(Offers);
