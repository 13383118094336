import React from 'react';

import './index.css';

function Loader() {
  return (
    <div className="loader">
      {
        Array(12).join(' ').split(' ').map((i, index) => (
          <span className="loader__dot" key={index}/>
        ))
      }
    </div>
  );
}

export default Loader;
