import React, { useState, useRef } from 'react';
import { Field } from 'redux-form';
import { useDrag, useDrop } from 'react-dnd';

import Input from '../../components/Input';
import Select from '../../components/Select';

const CardOffer = ({
  index,
  id,
  offer,
  fullMode,
  moveOffer,
  onRemove,
  categories,
  listCpa,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current || fullMode) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveOffer(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    item: { type: 'card', id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleRemove = () => {
    const isReady = window.confirm('Are you ready?');

    if (isReady) {
      onRemove();
    }
  };

  drag(drop(ref));

  return (
    <article
      className={`offer ${showDetails ? 'offer_details' : ''} ${
        offer.isHidden ? 'offer_hidden' : ''
      }`}
      ref={ref}
    >
      <div className="offer__general">
        <div className="offer__logo">
          <div className="offer__company">
            <img src={offer.img} alt={offer.name} />

            <Field name={`${id}.img`} component={Input} />
          </div>
        </div>

        <div className="offer__info">
          <ul className="data">
            <li className="data__item">
              <Field
                name={`${id}.top`}
                type="checkbox"
                component={Input}
                label="Top"
              />
            </li>

            <li className="data__item">
              <Field
                name={`${id}.push.isActive`}
                type="checkbox"
                component={Input}
                label="Push"
              />
            </li>
          </ul>

          <ul className="data">
            {categories.map(({ value, label }) => (
              <li className="data__item" key={value}>
                <Field
                  name={`${id}.categories.${value}`}
                  component={Input}
                  type="checkbox"
                  label={label}
                />
              </li>
            ))}
          </ul>
        </div>

        <div className="offer__conditions">
          <div className="offer__actions">
            <div className="icon-wrap" onClick={() => handleRemove()}>
              <span className="icon icon_remove" />
            </div>

            <div className="icon-wrap">
              <Field
                name={`${id}.isHidden`}
                type="checkbox"
                component={Input}
                custom
              >
                <span className="icon icon_visibility" />
              </Field>
            </div>
          </div>
        </div>
      </div>

      {fullMode && (
        <div
          className="offer__options"
          onClick={() => setShowDetails(!showDetails)}
        >
          <div className="icon icon_large icon_options" />
        </div>
      )}

      <div className="offer__additional">
        <h2 className="title">General</h2>

        <ul className="data">
          <li className="data__item">
            <Field
              name={`${id}.cpa`}
              component={Select}
              list={listCpa}
              label="CPA"
            />
          </li>

          <li className="data__item">
            <Field name={`${id}.offer_id`} component={Input} label="ID" />
          </li>

          <li className="data__item">
            <Field name={`${id}.offer_name`} component={Input} label="Name" />
          </li>

          <li className="data__item">
            <Field
              name={`${id}.description`}
              component={Input}
              label="Description"
            />
          </li>

          <li className="data__item">
            <label>Amount</label>
            <Field name={`${id}.amount.from`} component={Input} long /> -{' '}
            <Field name={`${id}.amount.to`} component={Input} long /> ₴
          </li>

          <li className="data__item">
            <label>Percent</label>
            <Field name={`${id}.percent.from`} component={Input} short /> -{' '}
            <Field name={`${id}.percent.to`} component={Input} short /> %
          </li>

          <li className="data__item">
            <label>Term</label>
            <Field name={`${id}.term.from`} component={Input} short /> -{' '}
            <Field name={`${id}.term.to`} component={Input} short /> days
          </li>

          <li className="data__item">
            <Field
              name={`${id}.term.displayMonth`}
              type="checkbox"
              component={Input}
              label="displayMonth"
            >
              <span className="icon icon_visibility" />
            </Field>
          </li>
        </ul>

        <h2 className="title">Cloak</h2>

        <ul className="data">
          <li className="data__item">
            <Field
              name={`${id}.detail.license`}
              component={Input}
              label="Лицензия"
            />
          </li>

          <li className="data__item">
            <Field
              name={`${id}.detail.address`}
              component={Input}
              label="Адрес"
            />
          </li>

          <li className="data__item">
            <Field
              name={`${id}.detail.phone`}
              component={Input}
              label="Телефон"
            />
          </li>

          <li className="data__item">
            <Field
              name={`${id}.detail.email`}
              component={Input}
              label="E-mail"
            />
          </li>

          <li className="data__item">
            <Field name={`${id}.detail.site`} component={Input} label="Сайт" />
          </li>

          <li className="data__item">
            <Field name={`${id}.detail.apr`} component={Input} label="APR" />
          </li>
        </ul>

        <h2 className="title">Push</h2>

        <ul className="data">
          <li className="data__item">
            <Field name={`${id}.push.img`} component={Input} label="Banner" />
          </li>

          <li className="data__item">
            <Field
              name={`${id}.push.description`}
              component={Input}
              label="Description"
            />
          </li>
        </ul>
      </div>
    </article>
  );
};

export default CardOffer;
