import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, getFormValues } from 'redux-form';

import Input from '../../components/Input';

class FormApps extends React.Component {
  render() {
    const {
      handleSubmit,
      submitting,
      apps: { data },
      formSearch: { geo },
    } = this.props;

    const listApps = data[geo].map(({ appId, name }) => ({
      value: appId && appId.replace(/\./g, '_'),
      label: name,
    }));

    return (
      <form onSubmit={handleSubmit} className="form">
        {listApps.map(({ value, label }) => (
          <Field
            name={value}
            key={value}
            type="checkbox"
            component={Input}
            label={label}
          />
        ))}

        <div className="form__controls">
          <button className="btn" type="submit" disabled={submitting}>
            Save
          </button>
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'offers-apps',
    enableReinitialize: true,
  }),
  connect((state) => ({
    formSearch: getFormValues('offers-search')(state),
    apps: state.apps,
  })),
)(FormApps);
