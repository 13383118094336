import * as actions from '../constants/actionTypes';

const initialState = {
  fetching: true,
  data: {},
  currentPartner: [],
  error: null,
};

const partners = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PARTNERS:
      return {
        ...state,
        data: action.data,
        fetching: false,
      };
    case actions.CHANGE_PARTNER:
      return {
        ...state,
        currentPartner: action.currentPartner,
      };
    case actions.PARTNERS_FETCHING:
      return {
        ...state,
        fetching: typeof action.status === 'boolean' ? action.status : true,
      };
    case actions.PARTNERS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    default:
      return state;
  }
};

export default partners;
