import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import Input from '../../components/Input';

class FormGeo extends React.Component {
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="form">
        <Field name="geo" component={Input} className="Input" label="GEO" />

        <div className="form__controls">
          <button className="btn" type="submit" disabled={submitting}>
            Add
          </button>
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'partners-app',
    enableReinitialize: true,
  }),
)(FormGeo);
