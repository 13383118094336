import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import auth from './auth';
import modal from './modal';
import apps from './apps';
import partners from './partners';
import offers from './offers';

export default combineReducers({
  form,
  auth,
  modal,
  apps,
  partners,
  offers,
});
