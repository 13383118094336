import * as actions from '../constants/actionTypes';

const initialState = {
  fetching: true,
  uid: localStorage.getItem('uid'),
  error: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN:
      return {
        ...state,
        uid: action.uid,
        fetching: false,
      };
    case actions.LOGOUT:
      return {
        ...state,
        uid: null,
        fetching: false,
      };
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    default:
      return state;
  }
};

export default auth;
