import * as actions from '../constants/actionTypes';

const initialState = {
  fetching: true,
  data: {},
  currentApp: {},
  error: null,
};

const apps = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_APPS:
      return {
        ...state,
        data: action.data,
        fetching: false,
      };
    case actions.CHANGE_APP:
      return {
        ...state,
        currentApp: action.currentApp,
      };
    case actions.APPS_FETCHING:
      return {
        ...state,
        fetching: typeof action.status === 'boolean' ? action.status : true,
      };
    case actions.APPS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    default:
      return state;
  }
};

export default apps;
