import React from 'react';

const Input = ({
  input,
  label,
  type,
  meta: { submitFailed, error },
  short,
  long,
  id,
  placeholder,
  children,
  custom,
}) => {
  const classInvalid = submitFailed && error ? 'field_invalid' : '';
  const classCheckbox = type === 'checkbox' ? 'field_checkbox' : '';
  const classWidth = short ? 'input_short' : long ? 'input_long' : '';
  const rand = id || Math.floor(Math.random() * 1000);

  return (
    <div className={`field ${classCheckbox} ${classInvalid}`}>
      <label
        htmlFor={rand}
        className={`label ${
          custom && type === 'checkbox' ? 'label_custom' : ''
        }`}
      >
        {custom && type === 'checkbox' ? children : label}
      </label>
      <input
        {...input}
        type={type}
        className={`input ${classWidth} ${
          custom && type === 'checkbox' ? 'input_hidden' : ''
        }`}
        id={rand}
        placeholder={placeholder}
      />
      {submitFailed && error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Input;
