import * as actions from '../constants/actionTypes';

export const getApps = () => async (dispatch) => {
  try {
    dispatch(appsFetching());

    const response = await fetch('https://api.orbitrush.com/apps');
    const result = await response.json();

    dispatch(setApps(result));

    return result;
  } catch (error) {
    dispatch(appsFailure(error));
  }
};

export const addGeo = (data) => async (dispatch) => {
  try {
    dispatch(appsFetching());

    const response = await fetch('https://api.orbitrush.com/apps', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    dispatch(getApps());

    return response;
  } catch (error) {
    dispatch(appsFailure(error));
  }
};

export const addApp = (data) => async (dispatch) => {
  try {
    dispatch(appsFetching());

    const response = await fetch('https://api.orbitrush.com/apps', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    dispatch(getApps());

    return response;
  } catch (error) {
    dispatch(appsFailure(error));
  }
};

export const removeApp = (appId) => async (dispatch) => {
  try {
    dispatch(appsFetching());

    const response = await fetch('https://api.orbitrush.com/apps', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ appId }),
    });

    dispatch(getApps());

    return response;
  } catch (error) {
    dispatch(appsFailure(error));
  }
};

export const updateApp = (data) => async (dispatch) => {
  try {
    dispatch(appsFetching());

    const response = await fetch('https://api.orbitrush.com/apps', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    dispatch(getApps());

    return response;
  } catch (error) {
    dispatch(appsFailure(error));
  }
};

export const changeApp = (currentApp) => ({
  type: actions.CHANGE_APP,
  currentApp,
});

const appsFetching = (status) => ({
  type: actions.APPS_FETCHING,
  status,
});

const appsFailure = (error) => ({
  type: actions.APPS_FAILURE,
  error,
});

export const setApps = (data) => ({
  type: actions.SET_APPS,
  data,
});
