import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, change } from 'redux-form';

import Select from '../../components/Select';

import { getApps } from '../../actions/apps';
import { getPartners, changePartner } from '../../actions/partners';

class FormSearch extends React.Component {
  state = {
    apps: {},
    partners: {},
    listGeo: [],
    listApps: [],
  };

  componentDidMount() {
    const {
      onChange,
      onGetApps,
      selectApp,
      onGetPartners,
      onChangePartner,
      initialValues: { geo },
    } = this.props;

    Promise.all([onGetApps(), onGetPartners()]).then(([apps, partners]) => {
      const listGeo = Object.entries(apps).map(([geo]) => ({
        value: geo,
        label: geo.toUpperCase(),
      }));

      const appsUpgrade = Object.entries(apps).reduce(
        (acc, [country, list]) => {
          const currentApps = list.map(({ appId, name }) => ({
            value: appId,
            label: name,
          }));

          acc[country] = Array.isArray(acc[country])
            ? acc[country].concat(currentApps)
            : currentApps;

          return acc;
        },
        {},
      );

      const cpaUpgrade = Object.entries(partners).reduce(
        (acc, [country, list]) => {
          const currentPartner = list.map(({ cpa }) => ({
            value: cpa,
            label: cpa,
          }));

          acc[country] = Array.isArray(acc[country])
            ? acc[country].concat(currentPartner)
            : currentPartner;

          return acc;
        },
        {},
      );

      const currentApp = appsUpgrade[geo][0].value;

      this.setState(
        {
          listGeo,
          listApps: appsUpgrade[geo],
          apps: appsUpgrade,
          partners: cpaUpgrade,
        },
        () => {
          onChange('offers-search', 'app', currentApp);
          selectApp(currentApp);
          onChangePartner(cpaUpgrade.all.concat(cpaUpgrade[geo]));
        },
      );
    });
  }

  handleChangeGeo(geo) {
    const { onChange, selectApp, onChangePartner } = this.props;
    const { apps, partners } = this.state;

    const currentApp = apps[geo][0].value;

    this.setState(
      {
        listApps: apps[geo],
      },
      () => {
        onChange('offers-search', 'app', currentApp);
        selectApp(currentApp);
        onChangePartner(partners.all.concat(partners[geo]));
      },
    );
  }

  handleChangeApp(appId) {
    const { selectApp } = this.props;

    selectApp(appId);
  }

  render() {
    const { listGeo, listApps } = this.state;

    return (
      <form className="offers-search">
        <Field
          name="geo"
          component={Select}
          list={listGeo}
          label="GEO"
          onChange={(e, geo) => this.handleChangeGeo(geo)}
        />

        <Field
          name="app"
          component={Select}
          list={listApps}
          label="APP"
          onChange={(e, appId) => this.handleChangeApp(appId)}
        />
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'offers-search',
    enableReinitialize: true,
  }),
  connect(
    (state) => ({}),
    (dispatch, props) => ({
      onChange: (form, name, value) => dispatch(change(form, name, value)),
      onGetApps: () => dispatch(getApps()),
      onGetPartners: () => dispatch(getPartners()),
      onChangePartner: (data) => dispatch(changePartner(data)),
    }),
  ),
)(FormSearch);
