import React from 'react';
import { Field } from 'redux-form';

import Input from '../../components/Input';

class CardCategory extends React.Component {
  render() {
    const { id, onRemove } = this.props;

    return (
      <tr>
        <td>
          <Field name={`${id}.label`} component={Input} />
        </td>
        <td>
          <Field name={`${id}.value`} component={Input} />
        </td>
        <td>
          <span className="icon icon_remove" onClick={onRemove} />
        </td>
      </tr>
    );
  }
}

export default CardCategory;
