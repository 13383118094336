import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, isAuth, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuth ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/login' }} />
          )
        }
      />
    );
  }
}

export default connect((state) => ({
  isAuth: state.auth.uid,
}))(PrivateRoute);
