import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FieldArray, getFormValues, reduxForm } from 'redux-form';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import CardCategory from './CardCategory';
import CardOffer from './CardOffer';

import { offerTemplate } from '../../helpers';

const Categories = ({ fields }) => (
  <React.Fragment>
    <header className="form-offers__subheader">
      <h2 className="title">Categories</h2>

      <button
        className="btn btn_info"
        type="button"
        onClick={() => fields.push({ label: '', value: 'new' })}
      >
        Add
      </button>
    </header>

    <table className="table_categories">
      <thead>
        <tr>
          <th>Label</th>
          <th>Value</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((category, index) => (
          <CardCategory
            key={index}
            index={index}
            id={category}
            onRemove={() => fields.remove(index)}
          />
        ))}
      </tbody>
    </table>
  </React.Fragment>
);

const Offers = ({ fields, categories, listCpa }) => {
  const extractFullMode = (value) => {
    return value === 'true';
  };

  const [fullMode, setFullMode] = useState(
    extractFullMode(localStorage.getItem('fullMode')),
  );

  const handleSetFullMode = (value) => {
    localStorage.setItem('fullMode', value);
    setFullMode(value);
  };

  return (
    <React.Fragment>
      <header className="form-offers__header">
        <div className="form-offers__controls">
          <h2 className="title">Offers</h2>

          <span
            className={`icon ${fullMode ? 'icon_layer-large' : 'icon_layer'}`}
            onClick={() => handleSetFullMode(!fullMode)}
          />
        </div>

        <button
          className="btn btn_info"
          type="button"
          onClick={() => fields.push(offerTemplate)}
        >
          Add
        </button>
      </header>

      <section
        className={`form-offers__body ${
          fullMode ? 'form-offers__body_full' : 'form-offers__body_short'
        }`}
      >
        <DndProvider backend={Backend}>
          {fields.map((offer, index) => (
            <CardOffer
              key={index}
              index={index}
              id={offer}
              offer={fields.get(index)}
              fullMode={fullMode}
              moveOffer={(a, b) => fields.move(a, b)}
              onRemove={() => fields.remove(index)}
              categories={categories}
              listCpa={listCpa}
            />
          ))}
        </DndProvider>
      </section>
    </React.Fragment>
  );
};

class FormOffers extends React.Component {
  render() {
    const {
      formOffers: { categories },
      partners: { currentPartner },
    } = this.props;

    return (
      <form className="form-offers">
        <FieldArray
          name="list"
          component={Offers}
          categories={categories}
          listCpa={currentPartner}
          rerenderOnEveryChange
        />

        <FieldArray
          name="categories"
          component={Categories}
          rerenderOnEveryChange
        />
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'offers-offer',
    enableReinitialize: true,
  }),
  connect(
    (state) => ({
      formOffers: getFormValues('offers-offer')(state),
      partners: state.partners,
    }),
    (dispatch, props) => ({}),
  ),
)(FormOffers);
