import React from 'react';

import Header from '../../components/Header';

import './index.css';

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <section className="layout">
        <Header />

        <div className="layout__container container">{children}</div>
      </section>
    );
  }
}

export default Layout;
